const _cn = "  _fd-column _miw-0 _mih-0 _pos-relative _fb-auto _dsp-flex _fs-0 _ai-stretch ";
import { Button, Theme, YStack, styled } from 'tamagui';
const StyledButton = styled(Button, {
  pressStyle: {
    scale: 0.5
  },
  variants: {
    reddish: {
      true: {
        pressStyle: {
          backgroundColor: 'red'
        }
      }
    }
  }
});
const DoubleStyledButtonNoVariants = styled(StyledButton, {});
const StyledButtonVariantTheme = styled(Button, {
  pressStyle: {
    scale: 0.5
  },
  variants: {
    testVariant: {
      true: {
        backgroundColor: '$background'
      }
    }
  }
});
export default (() => <>
    <StyledButton id="test" reddish>
      test
    </StyledButton>
    <StyledButton id="animated" reddish animation="quick">
      test
    </StyledButton>
    <DoubleStyledButtonNoVariants reddish id="double-styled">
      test
    </DoubleStyledButtonNoVariants>

    <Theme name="light">
      <_TamaguiTheme name="green"><div className={_cn}>
        <StyledButtonVariantTheme id="variant-theme" testVariant>
          test
        </StyledButtonVariantTheme>
      </div></_TamaguiTheme>
    </Theme>
  </>);
import { Theme as _TamaguiTheme } from "@tamagui/core";

require("/Users/mohammadjkhazali/Downloads/tamagui-1.30.0/apps/sandbox/usecases/StyledButtonVariantPseudoMerge.tsx.24.tamagui.css!=!/Users/mohammadjkhazali/Downloads/tamagui-1.30.0/packages/loader/dist/cjs/css.js?cssPath=/Users/mohammadjkhazali/Downloads/tamagui-1.30.0/apps/sandbox/usecases/StyledButtonVariantPseudoMerge.tsx.24.tamagui.css!/Users/mohammadjkhazali/Downloads/tamagui-1.30.0/apps/sandbox/usecases/StyledButtonVariantPseudoMerge.tsx")