const _cn2 = "  font_body _dsp-flex _col-d0t1114586361 ";
const _cn = "  _jc-center _fg-1 _fs-1 _fb-auto _fd-column _miw-0 _mih-0 _pos-relative _dsp-flex _ai-center ";
import { Stack, Text, YStack, styled } from 'tamagui';
const CustomYStack = styled(YStack, {
  debug: 'verbose',
  "backgroundColor": "_bg-d0t1708386504",
  hoverStyle: {
    backgroundColor: 'red'
  }
});
export default function SandboxPseudoStyleMerge() {
  return <div className={_cn}>
      <CustomYStack width={100} height={100} hoverStyle={{
      scale: 2
    }} animation="quick">
        <span className={_cn2}>hi</span>
      </CustomYStack>
    </div>;
}

require("/Users/mohammadjkhazali/Downloads/tamagui-1.30.0/apps/sandbox/usecases/PseudoStyleMerge.tsx.22.tamagui.css!=!/Users/mohammadjkhazali/Downloads/tamagui-1.30.0/packages/loader/dist/cjs/css.js?cssPath=/Users/mohammadjkhazali/Downloads/tamagui-1.30.0/apps/sandbox/usecases/PseudoStyleMerge.tsx.22.tamagui.css!/Users/mohammadjkhazali/Downloads/tamagui-1.30.0/apps/sandbox/usecases/PseudoStyleMerge.tsx")