import { Text, styled } from 'tamagui';
export const Heading = styled(Text, {
  name: 'Heading',
  "color": "_col-d0t1114586361",
  variants: ({
    type: {
      myVariant: {
        fontFamily: '$body',
        fontSize: 20,
        lh: '$24',
        fow: '$bold'
      }
    }
  } as const)
});
export default function SandboxVariantFontFamily() {
  return <Heading data-testid="heading" type="myVariant" fontSize="$1">
      H1
    </Heading>;
}

require("/Users/mohammadjkhazali/Downloads/tamagui-1.30.0/apps/sandbox/usecases/VariantFontFamily.tsx.31.tamagui.css!=!/Users/mohammadjkhazali/Downloads/tamagui-1.30.0/packages/loader/dist/cjs/css.js?cssPath=/Users/mohammadjkhazali/Downloads/tamagui-1.30.0/apps/sandbox/usecases/VariantFontFamily.tsx.31.tamagui.css!/Users/mohammadjkhazali/Downloads/tamagui-1.30.0/apps/sandbox/usecases/VariantFontFamily.tsx")