const _cn3 = "  font_body _dsp-flex _col-d0t52942863 ";
const _cn2 = "  _fd-column _miw-0 _mih-0 _pos-relative _fb-auto _dsp-flex _fs-0 _ai-stretch ";
const _cn = "  _gap-24 _jc-center _fg-1 _fs-1 _fb-auto _fd-column _miw-0 _mih-0 _pos-relative _dsp-flex _ai-center ";
import { Stack, Tooltip as TamaguiTooltip, Text, Theme, styled, withStaticProperties } from 'tamagui';
export const Tooltip = withStaticProperties(styled(TamaguiTooltip, {
  delay: 0,
  restMs: 0,
  allowFlip: true,
  stayInFrame: {
    mainAxis: true
  },
  placement: 'top'
}), {
  ...TamaguiTooltip,
  Content: styled(TamaguiTooltip.Content, {
    padding: 0,
    px: 8,
    zIndex: 1_000_000,
    br: 6,
    bg: '$color1',
    enterStyle: {
      x: 0,
      y: 5,
      o: 0,
      scale: 0.9
    },
    exitStyle: {
      x: 0,
      y: 5,
      o: 0,
      scale: 0.9
    },
    scale: 1,
    x: 0,
    y: 0,
    o: 1,
    animation: ['quick', {
      opacity: {
        overshootClamping: true
      }
    }]
  })
});
const TriggerText = styled(Text, {
  fontFamily: '$body',
  bg: '$color3',
  p: '$3',
  "color": "_col-d0t79723050"
});
export default function SandboxCustomStyledAnimatedTooltip() {
  return <div className={_cn}>
      <_TamaguiTheme name="red"><div className={_cn2}>
        <Tooltip>
          <Tooltip.Trigger>
            <TriggerText>No animation with styled()</TriggerText>
          </Tooltip.Trigger>

          <Theme inverse>
            <Tooltip.Content>
              <_TamaguiTheme name="red"><span className={_cn3}>
                Hey there!
              </span></_TamaguiTheme>
            </Tooltip.Content>
          </Theme>
        </Tooltip>
      </div></_TamaguiTheme>

      <_TamaguiTheme name="blue"><div className={_cn2}>
        {/* <TamaguiTooltip delay={0} restMs={0}>
          <TamaguiTooltip.Trigger>
            <TriggerText>This works with inline props</TriggerText>
          </TamaguiTooltip.Trigger>
           <Theme inverse>
            <TamaguiTooltip.Content
              {...{
                padding: 0,
                px: 8,
                zIndex: 1_000_000,
                br: 6,
                bg: '$color1',
                enterStyle: { x: 0, y: 5, o: 0, scale: 0.9 },
                exitStyle: { x: 0, y: 5, o: 0, scale: 0.9 },
                scale: 1,
                x: 0,
                y: 0,
                o: 1,
                animation: [
                  'quick',
                  {
                    opacity: {
                      overshootClamping: true,
                    },
                  },
                ],
              }}
            >
              <Text color="$color12">Hey there!</Text>
            </TamaguiTooltip.Content>
          </Theme>
         </TamaguiTooltip> */}
      </div></_TamaguiTheme>
    </div>;
}
import { Theme as _TamaguiTheme } from "@tamagui/core";

require("/Users/mohammadjkhazali/Downloads/tamagui-1.30.0/apps/sandbox/usecases/CustomStyledAnimatedTooltip.tsx.14.tamagui.css!=!/Users/mohammadjkhazali/Downloads/tamagui-1.30.0/packages/loader/dist/cjs/css.js?cssPath=/Users/mohammadjkhazali/Downloads/tamagui-1.30.0/apps/sandbox/usecases/CustomStyledAnimatedTooltip.tsx.14.tamagui.css!/Users/mohammadjkhazali/Downloads/tamagui-1.30.0/apps/sandbox/usecases/CustomStyledAnimatedTooltip.tsx")