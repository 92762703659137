const _cn4 = "  font_body _dsp-flex _col-d0t52942863 ";
const _cn3 = "  font_body _dsp-flex _col-d0t1114586361 ";
const _cn2 = "  _fd-column _miw-0 _mih-0 _pos-relative _fb-auto _dsp-flex _fs-0 _ai-stretch ";
const _cn = "  _gap-24 _jc-center _fg-1 _fs-1 _fb-auto _fd-column _miw-0 _mih-0 _pos-relative _dsp-flex _ai-center ";
import React from 'react';
import { Stack, Popover as TamaguiPopover, Text, Theme, styled, withStaticProperties } from 'tamagui';
export const Popover = withStaticProperties(TamaguiPopover, {
  ...TamaguiPopover,
  Content: styled(TamaguiPopover.Content, {
    padding: 0,
    zIndex: 1_000_000,
    enterStyle: {
      x: 0,
      y: 5,
      opacity: 0,
      scale: 0.9
    },
    exitStyle: {
      x: 0,
      y: 5,
      opacity: 0,
      scale: 0.9
    },
    scale: 1,
    x: 0,
    y: 0,
    opacity: 1,
    animation: ['quick', {
      opacity: {
        overshootClamping: true
      }
    }]
  })
});
export default function SandboxCustomStyledAnimatedPopover() {
  return <div className={_cn}>
      <_TamaguiTheme name="red"><div className={_cn2}>
        <Popover>
          <Popover.Trigger>
            <span className={_cn3}>No animation with styled()</span>
          </Popover.Trigger>

          <Theme inverse>
            <Popover.Content>
              <_TamaguiTheme name="red"><span className={_cn4}>
                Hey there!
              </span></_TamaguiTheme>
            </Popover.Content>
          </Theme>
        </Popover>
      </div></_TamaguiTheme>
    </div>;
}
import { Theme as _TamaguiTheme } from "@tamagui/core";

require("/Users/mohammadjkhazali/Downloads/tamagui-1.30.0/apps/sandbox/usecases/CustomStyledAnimatedPopover.tsx.13.tamagui.css!=!/Users/mohammadjkhazali/Downloads/tamagui-1.30.0/packages/loader/dist/cjs/css.js?cssPath=/Users/mohammadjkhazali/Downloads/tamagui-1.30.0/apps/sandbox/usecases/CustomStyledAnimatedPopover.tsx.13.tamagui.css!/Users/mohammadjkhazali/Downloads/tamagui-1.30.0/apps/sandbox/usecases/CustomStyledAnimatedPopover.tsx")