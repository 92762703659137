import {
  isWeb,
  setupReactNative,
  styled,
  useProps
} from "@tamagui/core";
import { forwardRef } from "react";
import { Image as RNImage } from "react-native";
setupReactNative({
  Image: RNImage
});
const StyledImage = styled(RNImage, {
  name: "Image",
  position: "relative",
  source: { uri: "" },
  zIndex: 1
});
let hasWarned = false;
const Image = StyledImage.extractable(
  forwardRef((inProps, ref) => {
    const props = useProps(inProps);
    const { src, source, ...rest } = props;
    if (process.env.NODE_ENV === "development") {
      if (typeof src === "string") {
        if (typeof props.width === "string" && props.width[0] !== "$" || typeof props.height === "string" && props.height[0] !== "$") {
          if (!hasWarned) {
            hasWarned = true;
            console.warn(
              `React Native expects a numerical width/height. If you want to use a percent you must define the "source" prop with width, height, and uri.`
            );
          }
        }
      }
    }
    const finalSource = typeof src === "string" ? { uri: src, ...isWeb && { width: props.width, height: props.height } } : source ?? src;
    return /* @__PURE__ */ React.createElement(StyledImage, { ref, source: finalSource, ...rest });
  })
);
Image.getSize = RNImage.getSize;
Image.getSizeWithHeaders = RNImage.getSizeWithHeaders;
Image.prefetch = RNImage.prefetch;
Image.prefetchWithMetadata = RNImage.prefetchWithMetadata;
Image.abortPrefetch = RNImage.abortPrefetch;
Image.queryCache = RNImage.queryCache;
export {
  Image
};
