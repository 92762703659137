var __defProp = Object.defineProperty;
var __name = (target, value) => __defProp(target, "name", { value, configurable: true });
import { getFontSize } from "@tamagui/font-size";
import { getSpace } from "@tamagui/get-token";
import { useGetThemedIcon } from "@tamagui/helpers-tamagui";
import { ThemeableStack, YStack } from "@tamagui/stacks";
import { SizableText, wrapChildrenInText } from "@tamagui/text";
import {
  Spacer,
  getTokens,
  getVariableValue,
  styled,
  useProps,
  withStaticProperties
} from "@tamagui/web";
const NAME = "ListItem";
const listItemVariants = {
  unstyled: {
    false: {
      size: "$true",
      alignItems: "center",
      flexWrap: "nowrap",
      width: "100%",
      borderColor: "$borderColor",
      maxWidth: "100%",
      overflow: "hidden",
      flexDirection: "row",
      backgroundColor: "$background"
    }
  },
  size: {
    "...size": (val, { tokens }) => {
      return {
        minHeight: tokens.size[val],
        paddingHorizontal: tokens.space[val],
        paddingVertical: getSpace(tokens.space[val], {
          shift: -4
        })
      };
    }
  },
  active: {
    true: {
      hoverStyle: {
        backgroundColor: "$background"
      }
    }
  },
  disabled: {
    true: {
      opacity: 0.5,
      // TODO breaking types
      pointerEvents: "none"
    }
  }
};
const ListItemFrame = styled(ThemeableStack, {
  name: NAME,
  tag: "li",
  variants: listItemVariants,
  defaultVariants: {
    unstyled: false
  }
});
const ListItemText = styled(SizableText, {
  name: "ListItemText",
  variants: {
    unstyled: {
      false: {
        color: "$color",
        flexGrow: 1,
        flexShrink: 1,
        ellipse: true,
        cursor: "default"
      }
    }
  },
  defaultVariants: {
    unstyled: false
  }
});
const ListItemSubtitle = styled(ListItemText, {
  name: "ListItemSubtitle",
  variants: {
    unstyled: {
      false: {
        opacity: 0.6,
        maxWidth: "100%",
        size: "$3",
        color: "$color"
      }
    }
  },
  defaultVariants: {
    unstyled: false
  }
});
const ListItemTitle = styled(ListItemText, {
  name: "ListItemTitle"
});
const useListItem = /* @__PURE__ */ __name((props, {
  Text = ListItemText,
  Subtitle = ListItemSubtitle,
  Title = ListItemTitle
} = { Text: ListItemText, Subtitle: ListItemSubtitle, Title: ListItemTitle }) => {
  const {
    children,
    icon,
    iconAfter,
    noTextWrap,
    theme: themeName,
    space,
    spaceFlex,
    scaleIcon = 1,
    scaleSpace = 1,
    subTitle,
    // text props
    color,
    fontWeight,
    letterSpacing,
    fontSize,
    fontFamily,
    textAlign,
    textProps,
    title,
    ...rest
  } = props;
  const mediaActiveProps = useProps(props);
  const size = mediaActiveProps.size || "$true";
  const subtitleSize = `$${+String(size).replace("$", "") - 1}`;
  const iconSize = getFontSize(size) * scaleIcon;
  const getThemedIcon = useGetThemedIcon({ size: iconSize, color });
  const [themedIcon, themedIconAfter] = [icon, iconAfter].map(getThemedIcon);
  const spaceSize = getVariableValue(getTokens().space[mediaActiveProps.space] ?? iconSize) * scaleSpace;
  const contents = wrapChildrenInText(Text, mediaActiveProps);
  return {
    props: {
      fontFamily,
      ...rest,
      children: /* @__PURE__ */ React.createElement(React.Fragment, null, themedIcon ? /* @__PURE__ */ React.createElement(React.Fragment, null, themedIcon, /* @__PURE__ */ React.createElement(Spacer, { size: spaceSize })) : null, title || subTitle ? /* @__PURE__ */ React.createElement(YStack, { flex: 1 }, noTextWrap === "all" ? title : /* @__PURE__ */ React.createElement(Title, { size }, title), subTitle ? /* @__PURE__ */ React.createElement(React.Fragment, null, typeof subTitle === "string" && noTextWrap !== "all" ? (
        // TODO can use theme but we need to standardize to alt themes
        // or standardize on subtle colors in themes
        /* @__PURE__ */ React.createElement(Subtitle, { size: subtitleSize }, subTitle)
      ) : subTitle) : null, contents) : contents, themedIconAfter ? /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Spacer, { size: spaceSize }), themedIconAfter) : null)
    }
  };
}, "useListItem");
const ListItemComponent = ListItemFrame.styleable(/* @__PURE__ */ __name(function ListItem(props, ref) {
  const { props: listItemProps } = useListItem(props);
  return /* @__PURE__ */ React.createElement(ListItemFrame, { ref, justifyContent: "space-between", ...listItemProps });
}, "ListItem"));
const listItemStaticConfig = {
  inlineProps: /* @__PURE__ */ new Set([
    // text props go here (can't really optimize them, but we never fully extract listItem anyway)
    "color",
    "fontWeight",
    "fontSize",
    "fontFamily",
    "letterSpacing",
    "textAlign"
  ])
};
const ListItem2 = withStaticProperties(ListItemComponent, {
  Text: ListItemText,
  Subtitle: ListItemSubtitle
});
export {
  ListItem2 as ListItem,
  ListItemFrame,
  ListItemSubtitle,
  ListItemText,
  ListItemTitle,
  listItemStaticConfig,
  listItemVariants,
  useListItem
};
