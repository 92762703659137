var __defProp = Object.defineProperty;
var __name = (target, value) => __defProp(target, "name", { value, configurable: true });
import { composeRefs } from "@tamagui/compose-refs";
import { isTamaguiComponent, useEvent } from "@tamagui/web";
import { useCallback, useEffect, useRef } from "react";
import { registerFocusable } from "./registerFocusable";
function focusableInputHOC(Component) {
  const component = Component.styleable(
    (props, ref) => {
      const isInput = isTamaguiComponent(Component) && Component.staticConfig.isInput;
      const inputValue = useRef(props.value || props.defaultValue || "");
      const unregisterFocusable = useRef();
      const inputRef = useCallback(
        (input) => {
          if (!props.id)
            return;
          if (!input)
            return;
          unregisterFocusable.current?.();
          unregisterFocusable.current = registerFocusable(props.id, {
            focus: input.focus,
            ...isInput && {
              // react-native doesn't support programmatic .select()
              focusAndSelect() {
                input.focus();
                if (input.setSelection && typeof inputValue.current === "string") {
                  input.setSelection(0, inputValue.current.length);
                }
              }
            }
          });
        },
        [isInput, props.id]
      );
      const combinedRefs = composeRefs(ref, inputRef);
      useEffect(() => {
        return () => {
          unregisterFocusable.current?.();
        };
      }, []);
      const onChangeText = useEvent((value) => {
        inputValue.current = value;
        props.onChangeText?.(value);
      });
      const finalProps = isInput ? {
        ...props,
        onChangeText
      } : props;
      return /* @__PURE__ */ React.createElement(Component, { ref: combinedRefs, ...finalProps });
    }
  );
  return component;
}
__name(focusableInputHOC, "focusableInputHOC");
export {
  focusableInputHOC
};
