const _cn3 = "  is_Square _bg-d0t79723050 _w-20 _h-20 _miw-20 _maw-20 _mah-20 _mih-20 _jc-center _fd-column _pos-relative _fb-auto _dsp-flex _fs-0 _ai-center ";
const _cn2 = "  _gap-24 _jc-center _pt-18 _pr-18 _pb-18 _pl-18 _btc-d0t1948734170 _brc-d0t1948734170 _bbc-d0t1948734170 _blc-d0t1948734170 _btw-1 _brw-1 _bbw-1 _blw-1 _fd-column _miw-0 _mih-0 _pos-relative _fb-auto _dsp-flex _fs-0 _ai-center _bs-solid ";
const _cn = "  is_Card _h-100 _w-100 _btlr-9 _btrr-9 _bbrr-9 _bblr-9 _bg-f9f9f935 _pos-relative _ov-hidden _fd-column _miw-0 _mih-0 _fb-auto _dsp-flex _fs-0 _ai-stretch ";
import { memo, useMemo, useState } from 'react';
import { Button, Card, Square, Theme, YStack } from 'tamagui';
export default function SandboxThemeChange() {
  return <Button themeInverse>inverse</Button>;
  return <>
      <Inner>
        <Inner>
          <div className={_cn} />
        </Inner>
      </Inner>
    </>;
}
export function Inner(props: {
  children?: any;
}) {
  const [theme, setTheme] = useState(('yellow' as any));
  return <div className={_cn2}>
      <pre>
        <code>
          <b>Inner</b>{' '}
          {JSON.stringify({
          theme
        })}
        </code>
      </pre>

      <Button onPress={() => {
      setTheme(theme === 'yellow' ? 'blue' : 'yellow');
    }}>
        Change Theme
      </Button>

      {/* @ts-ignore */}
      <Theme name={theme}>
        <SandboxThemeChildStatic />
        <SandboxThemeChildDynamic />

        <Button themeInverse>inverse</Button>

        {props.children}
      </Theme>
    </div>;
}
const SandboxThemeChildStatic = memo(() => {
  // @ts-ignore
  return <div className={_cn3} />;
});
const SandboxThemeChildDynamic = memo(() => {
  console.log('render dynamic');
  return (
    // @ts-ignore
    <Square animation="bouncy" size={20} backgroundColor="$color10" />
  );
});

require("/Users/mohammadjkhazali/Downloads/tamagui-1.30.0/apps/sandbox/usecases/ThemeChange.tsx.32.tamagui.css!=!/Users/mohammadjkhazali/Downloads/tamagui-1.30.0/packages/loader/dist/cjs/css.js?cssPath=/Users/mohammadjkhazali/Downloads/tamagui-1.30.0/apps/sandbox/usecases/ThemeChange.tsx.32.tamagui.css!/Users/mohammadjkhazali/Downloads/tamagui-1.30.0/apps/sandbox/usecases/ThemeChange.tsx")