var __defProp = Object.defineProperty;
var __name = (target, value) => __defProp(target, "name", { value, configurable: true });
import { _mutateTheme } from "./_mutateTheme";
function addTheme(props) {
  return _mutateTheme({ ...props });
}
__name(addTheme, "addTheme");
export {
  addTheme
};
