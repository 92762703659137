var __defProp = Object.defineProperty;
var __name = (target, value) => __defProp(target, "name", { value, configurable: true });
import { getConfig, isVariable } from "@tamagui/core";
const getFontSize = /* @__PURE__ */ __name((inSize, opts) => {
  const res = getFontSizeVariable(inSize, opts);
  if (isVariable(res)) {
    return +res.val;
  }
  return res ? +res : 16;
}, "getFontSize");
const getFontSizeVariable = /* @__PURE__ */ __name((inSize, opts) => {
  const token = getFontSizeToken(inSize, opts);
  if (!token) {
    return inSize;
  }
  const conf = getConfig();
  return conf.fontsParsed[opts?.font || "$body"].size[token];
}, "getFontSizeVariable");
const getFontSizeToken = /* @__PURE__ */ __name((inSize, opts) => {
  if (typeof inSize === "number") {
    return null;
  }
  const relativeSize = opts?.relativeSize || 0;
  const conf = getConfig();
  const fontSize = conf.fontsParsed[opts?.font || "$body"].size;
  const size = inSize || ("$true" in fontSize ? "$true" : "$4");
  const sizeTokens = Object.keys(fontSize);
  let foundIndex = sizeTokens.indexOf(size);
  if (foundIndex === -1) {
    if (size.endsWith(".5")) {
      foundIndex = sizeTokens.indexOf(size.replace(".5", ""));
    }
  }
  if (process.env.NODE_ENV === "development") {
    if (foundIndex === -1) {
      console.warn("No font size found", size, opts, "in size tokens", sizeTokens);
    }
  }
  const tokenIndex = Math.min(
    Math.max(0, foundIndex + relativeSize),
    sizeTokens.length - 1
  );
  return sizeTokens[tokenIndex] ?? size;
}, "getFontSizeToken");
export {
  getFontSize,
  getFontSizeToken,
  getFontSizeVariable
};
