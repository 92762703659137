var __defProp = Object.defineProperty;
var __name = (target, value) => __defProp(target, "name", { value, configurable: true });
import { YStack } from "tamagui";
import { TamaguiIconSvg } from "./TamaguiLogoSvg";
const LogoIcon = /* @__PURE__ */ __name(({ downscale = 2 }) => {
  return /* @__PURE__ */ React.createElement(
    YStack,
    {
      tag: "span",
      className: "unselectable",
      alignSelf: "center",
      marginVertical: -10,
      pressStyle: {
        opacity: 0.7,
        scaleX: -1
      }
    },
    /* @__PURE__ */ React.createElement(
      TamaguiIconSvg,
      {
        className: "tamagui-icon",
        width: 450 / 8 / downscale,
        height: 420 / 8 / downscale
      }
    )
  );
}, "LogoIcon");
export {
  LogoIcon
};
