var __defProp = Object.defineProperty;
var __name = (target, value) => __defProp(target, "name", { value, configurable: true });
import { isServer } from "@tamagui/constants";
import {
  activeThemeManagers,
  ensureThemeVariable,
  getConfig,
  getThemeCSSRules,
  proxyThemeToParents,
  updateConfig
} from "@tamagui/web";
function _mutateTheme(props) {
  if (isServer) {
    if (process.env.NODE_ENV === "development") {
      console.warn("Theme mutation is not supported on server side");
    }
    return;
  }
  const config = getConfig();
  const { name: themeName, theme: themeIn, insertCSS, mutationType } = props;
  if (process.env.NODE_ENV === "development") {
    if (!config) {
      throw new Error("No config");
    }
    const theme2 = config.themes[props.name];
    if (mutationType && !theme2) {
      throw new Error(
        `${mutationType === "replace" ? "Replace" : "Update"} theme failed! Theme ${props.name} does not exist`
      );
    }
    if (!props.mutationType && theme2) {
      return { theme: theme2 };
    }
  }
  const theme = {
    ...mutationType === "update" ? config.themes[themeName] ?? {} : {},
    ...themeIn
  };
  for (const key in theme) {
    ensureThemeVariable(theme, key);
  }
  const themeProxied = proxyThemeToParents(themeName, theme, config.themes);
  config.themes[themeName] = themeProxied;
  let cssRules = [];
  updateConfig("themes", { ...config.themes, [themeName]: themeProxied });
  if (process.env.TAMAGUI_TARGET === "web") {
    if (insertCSS) {
      cssRules = getThemeCSSRules({
        // @ts-ignore this works but should be fixed types
        config,
        themeName,
        names: [themeName],
        theme
      });
      const id = `t_theme_style_${themeName}`;
      const existing = document.querySelector(`#${id}`);
      const style = document.createElement("style");
      style.id = id;
      style.appendChild(document.createTextNode(cssRules.join("\n")));
      document.head.appendChild(style);
      if (existing) {
        existing.parentElement?.removeChild(existing);
      }
    }
  }
  activeThemeManagers.forEach((manager) => {
    if (manager.state.name === props.name) {
      manager.updateState(
        {
          name: props.name,
          forceTheme: themeProxied
        },
        true
      );
    }
  });
  return {
    theme: themeProxied,
    cssRules
  };
}
__name(_mutateTheme, "_mutateTheme");
export {
  _mutateTheme
};
