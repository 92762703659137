const _cn7 = " _bg-f9f9f935";
const _cn6 = " _bg-d0t1821305091";
const _cn5 = " _h-10037 _fg-1 _fs-1 _fb-auto _jc-center _fd-column _miw-0 _mih-0 _pos-relative _dsp-flex _ai-center  ";
const _cn4 = "  is_Separator _h-auto _mah-auto _w-0 _maw-0 _bbw-0 _brw-1 _bs-solid _fg-1 _fs-1 _fb-auto _btw-0 _blw-0 _btc-d0t196083813 _brc-d0t196083813 _bbc-d0t196083813 _blc-d0t196083813 _fd-column _miw-0 _mih-0 _pos-relative _dsp-flex _ai-stretch _transform-d0t607864201 ";
const _cn3 = "  _h-10037 _fg-1 _fs-1 _fb-auto _jc-center _fd-column _miw-0 _mih-0 _pos-relative _dsp-flex _ai-center ";
const _cn2 = "  _pos-absolute _t-0 _l-0 _r-0 _b-0 _fd-row _miw-0 _mih-0 _fb-auto _dsp-flex _fs-0 _ai-stretch ";
const _cn = "  font_body _bg-f9f9f935 _dsp-flex _col-d0t1114586361 ";
import '@tamagui/core/reset.css';
import '@tamagui/polyfill-dev';
import * as Demos from '@tamagui/demos';
import { SandboxHeading } from '@tamagui/sandbox-ui';
import { ToastProvider } from '@tamagui/toast';
import { Suspense, useState } from 'react';
import { Button, Separator, TamaguiProvider, Theme, XStack, YStack, getStylesAtomic } from 'tamagui';
import { CustomButtonDemo } from './CustomButton';
import config from './tamagui.config';
import { TestModal } from './usecases/CrashAdaptSheet';

// useful for debugging why things render:
// import './wdyr'

if (typeof require !== 'undefined') {
  globalThis['React'] = require('react'); // webpack
}

export const Sandbox = () => {
  const demoComponentName = new URLSearchParams(window.location.search).get('demo');
  const useCaseComponentName = new URLSearchParams(window.location.search).get('test');
  const Component = demoComponentName ? Demos[demoComponentName.endsWith('Demo') ? demoComponentName : `${demoComponentName}Demo`] : useCaseComponentName ? require(`./usecases/${useCaseComponentName}`).default : SandboxInner;
  return <SandboxFrame>
      <Suspense fallback="Loading...">
        <Component />
      </Suspense>
    </SandboxFrame>;
};
const SandboxInner = () => {
  return <TestModal />;
  return <h1
  // $dark={{ backgroundColor: 'red' }}
  // $light={{ backgroundColor: 'green' }}
  className={_cn}>
      sssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssss
    </h1>;
  return <CustomButtonDemo />;
};

// function TestAnimatePresence() {
//   const [show, setShow] = useState(true)

//   return (
//     <>
//       <Button onPress={() => setShow(!show)}>hide</Button>
//       <AnimatePresence>
//         {show && (
//           <Square
//             animation="quick"
//             size={100}
//             bc="red"
//             y={0}
//             o={1}
//             hoverStyle={{
//               y: -10,
//             }}
//             enterStyle={{
//               y: -100,
//             }}
//             exitStyle={{
//               y: 100,
//               o: 0,
//             }}
//           />
//         )}
//       </AnimatePresence>
//     </>
//   )
// }

function TestPerf() {
  return <Button onPress={runTestPerf}>run</Button>;
}
function runTestPerf() {
  const start0 = performance.now();
  function baseline() {
    return new Array().fill(1000).map(() => {
      return [...new Set([`${Math.random() * Math.random() * Math.random()}`])];
    });
  }
  for (let i = 0; i < 10000; i++) {
    baseline();
  }
  const end0 = performance.now() - start0;
  console.log('end0', end0);
  const start = performance.now();
  function run() {
    getStylesAtomic({
      backgroundColor: 'red',
      width: 100,
      height: 200,
      scale: 2,
      $gtLg: {
        backgroundColor: 'green'
      },
      hoverStyle: {
        margin: 20,
        padding: 20
      }
    });
  }
  for (let i = 0; i < 10000; i++) {
    run();
  }
  console.log('took', performance.now() - start);
}
const SandboxFrame = (props: {
  children: any;
}) => {
  const [theme, setTheme] = useState(new URLSearchParams(window.location.search).get('theme') === 'dark' ? 'dark' : 'light');
  const [screenshot, setScreenshot] = useState(new URLSearchParams(window.location.search).has('screenshot'));
  const showThemeSwitch = !screenshot;
  const splitView = new URLSearchParams(window.location.search).get('splitView');
  return <TamaguiProvider config={config} defaultTheme={theme}>
      <ToastProvider swipeDirection="horizontal">
        <link href="/fonts/inter.css" rel="stylesheet" />

        <style type="text/css" dangerouslySetInnerHTML={{
        __html: `
            html, body, #root { height: 100vh; width: 100vw; display: flex; align-items: center; justify-content: center; }
          `
      }} />

        <Theme name={screenshot ? 'blue' : undefined}>
          <div className={_cn2}>
            <div className={_cn3}>
              {props.children}
            </div>

            {splitView ? <>
                <div className={_cn4} />
                <Theme name="dark">
                  <div className={concatClassName("" + (_cn5 + (screenshot ? _cn6 : _cn7)))}>
                    {props.children}
                  </div>
                </Theme>
              </> : null}
          </div>
        </Theme>
        {showThemeSwitch && <div style={{
        position: 'fixed',
        bottom: 30,
        left: 20,
        fontSize: 30
      }} onClick={() => setTheme(theme === 'light' ? 'dark' : 'light')}>
            🌗
          </div>}
      </ToastProvider>
    </TamaguiProvider>;
};
import { concatClassName } from "@tamagui/helpers";

require("/Users/mohammadjkhazali/Downloads/tamagui-1.30.0/apps/sandbox/Sandbox.tsx.1.tamagui.css!=!/Users/mohammadjkhazali/Downloads/tamagui-1.30.0/packages/loader/dist/cjs/css.js?cssPath=/Users/mohammadjkhazali/Downloads/tamagui-1.30.0/apps/sandbox/Sandbox.tsx.1.tamagui.css!/Users/mohammadjkhazali/Downloads/tamagui-1.30.0/apps/sandbox/Sandbox.tsx")