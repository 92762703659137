var __defProp = Object.defineProperty;
var __name = (target, value) => __defProp(target, "name", { value, configurable: true });
import { _mutateTheme } from "./_mutateTheme";
function replaceTheme({
  name,
  theme
}) {
  const next = _mutateTheme({ name, theme, insertCSS: true, mutationType: "replace" });
  return next;
}
__name(replaceTheme, "replaceTheme");
export {
  replaceTheme
};
