import { ButtonFrame, ButtonText, GetProps, styled, useButton } from 'tamagui';
const Frame = styled(ButtonFrame, {
  "backgroundColor": "_bg-d0t1948734170"
});
const Text = styled(ButtonText, {
  "fontSize": "_fos-13",
  "lineHeight": "_lh-25",
  "textTransform": "_tt-uppercase",
  "marginTop": "_mt-0",
  "marginBottom": "_mb-0"
});
type CustomButtonFrameProps = GetProps<typeof Frame>;
type CustomButtonTextProps = GetProps<typeof Text>;
type ButtonProps = CustomButtonFrameProps & CustomButtonTextProps;
export const Button = Frame.styleable<ButtonProps>((props, ref) => {
  const {
    props: buttonProps
  } = useButton(props, {
    Text
  });
  return <Frame ref={ref} {...buttonProps} />;
});

require("/Users/mohammadjkhazali/Downloads/tamagui-1.30.0/apps/sandbox/usecases/ButtonCustom.tsx.8.tamagui.css!=!/Users/mohammadjkhazali/Downloads/tamagui-1.30.0/packages/loader/dist/cjs/css.js?cssPath=/Users/mohammadjkhazali/Downloads/tamagui-1.30.0/apps/sandbox/usecases/ButtonCustom.tsx.8.tamagui.css!/Users/mohammadjkhazali/Downloads/tamagui-1.30.0/apps/sandbox/usecases/ButtonCustom.tsx")