const _cn5 = " _fos-16";
const _cn4 = " _fos-22";
const _cn3 = " _bg-f9f9f935 _dsp-flex _col-d0t1114586361  ";
const _cn2 = "  font_body";
const _cn = "  _w-550 _pr-7 _pl-7 _fd-column _miw-0 _mih-0 _pos-relative _fb-auto _dsp-flex _fs-0 _ai-stretch  _pr-_gtSm_32 _pl-_gtSm_32";
import { SandboxHeading, Stack } from '@tamagui/sandbox-ui';
export const CodeExamplesInput = props => <div className={_cn}>
    <h1 className={concatClassName(_cn2 + (_cn3 + (props.big ? _cn4 : _cn5)))}>
      Lorem ipsum dolor.
    </h1>
  </div>;
import { concatClassName } from "@tamagui/helpers";

require("/Users/mohammadjkhazali/Downloads/tamagui-1.30.0/apps/sandbox/usecases/CodeExamplesInput.tsx.10.tamagui.css!=!/Users/mohammadjkhazali/Downloads/tamagui-1.30.0/packages/loader/dist/cjs/css.js?cssPath=/Users/mohammadjkhazali/Downloads/tamagui-1.30.0/apps/sandbox/usecases/CodeExamplesInput.tsx.10.tamagui.css!/Users/mohammadjkhazali/Downloads/tamagui-1.30.0/apps/sandbox/usecases/CodeExamplesInput.tsx")