var __defProp = Object.defineProperty;
var __name = (target, value) => __defProp(target, "name", { value, configurable: true });
import {
  getConfig,
  getExpandedShorthands,
  getTokens,
  getVariableValue,
  isTamaguiElement,
  mergeProps,
  spacedChildren,
  styled,
  useProps,
  withStaticProperties
} from "@tamagui/core";
import { createContextScope } from "@tamagui/create-context";
import { ThemeableStack } from "@tamagui/stacks";
import { useControllableState } from "@tamagui/use-controllable-state";
import React, { Children, forwardRef, isValidElement } from "react";
import { ScrollView } from "react-native";
import { useIndex, useIndexedChildren } from "reforest";
const GROUP_NAME = "Group";
const [createGroupContext, createGroupScope] = createContextScope(GROUP_NAME);
const [GroupProvider, useGroupContext] = createGroupContext(GROUP_NAME);
const GroupFrame = styled(ThemeableStack, {
  name: "GroupFrame",
  variants: {
    unstyled: {
      false: {
        size: "$true",
        y: 0,
        backgroundColor: "$background"
      }
    },
    size: (val, { tokens }) => {
      const borderRadius = tokens.radius[val] ?? val ?? tokens.radius["$true"];
      return {
        borderRadius
      };
    }
  },
  defaultVariants: {
    unstyled: false
  }
});
function createGroup(verticalDefault) {
  return withStaticProperties(
    forwardRef((props, ref) => {
      const activeProps = useProps(props);
      const {
        __scopeGroup,
        children: childrenProp,
        space,
        size = "$true",
        spaceDirection,
        separator,
        scrollable,
        axis = verticalDefault ? "vertical" : "horizontal",
        orientation = axis,
        disabled: disabledProp,
        disablePassBorderRadius: disablePassBorderRadiusProp,
        borderRadius,
        forceUseItem,
        ...restProps
      } = getExpandedShorthands(activeProps);
      const vertical = orientation === "vertical";
      const [itemChildrenCount, setItemChildrenCount] = useControllableState({
        defaultProp: forceUseItem ? 1 : 0
      });
      const isUsingItems = itemChildrenCount > 0;
      const radius = borderRadius ?? (size ? getVariableValue(getTokens().radius[size]) - 1 : void 0);
      const hasRadius = radius !== void 0;
      const disablePassBorderRadius = disablePassBorderRadiusProp ?? !hasRadius;
      const childrenArray = Children.toArray(childrenProp);
      const children = isUsingItems ? childrenProp : childrenArray.map((child, i) => {
        if (!isValidElement(child)) {
          return child;
        }
        const disabled = child.props.disabled ?? disabledProp;
        const isFirst = i === 0;
        const isLast = i === childrenArray.length - 1;
        const radiusStyles = disablePassBorderRadius === true ? null : getBorderRadius({
          isFirst,
          isLast,
          radius,
          vertical,
          disable: disablePassBorderRadius
        });
        const props2 = {
          disabled,
          ...isTamaguiElement(child) ? radiusStyles : { style: radiusStyles }
        };
        return cloneElementWithPropOrder(child, props2);
      });
      const indexedChildren = useIndexedChildren(
        spacedChildren({
          direction: spaceDirection,
          separator,
          space,
          children
        })
      );
      const onItemMount = React.useCallback(
        () => setItemChildrenCount((prev) => prev + 1),
        []
      );
      const onItemUnmount = React.useCallback(
        () => setItemChildrenCount((prev) => prev - 1),
        []
      );
      return /* @__PURE__ */ React.createElement(
        GroupProvider,
        {
          disablePassBorderRadius,
          vertical: orientation === "vertical",
          radius,
          disabled: disabledProp,
          onItemMount,
          onItemUnmount,
          scope: __scopeGroup
        },
        /* @__PURE__ */ React.createElement(
          GroupFrame,
          {
            ref,
            size,
            flexDirection: orientation === "horizontal" ? "row" : "column",
            borderRadius,
            ...restProps
          },
          wrapScroll({ ...activeProps, orientation }, indexedChildren)
        )
      );
    }),
    {
      Item: GroupItem
    }
  );
}
__name(createGroup, "createGroup");
const GroupItem = /* @__PURE__ */ __name((props) => {
  const { __scopeGroup, children } = props;
  const groupItemProps = useGroupItem(
    { disabled: isValidElement(children) ? children.props.disabled : void 0 },
    __scopeGroup
  );
  if (!isValidElement(children)) {
    return children;
  }
  if (isTamaguiElement(children)) {
    return React.cloneElement(children, groupItemProps);
  }
  return React.cloneElement(children, {
    style: {
      ...children.props?.["style"],
      ...groupItemProps
    }
  });
}, "GroupItem");
const useGroupItem = /* @__PURE__ */ __name((childrenProps, __scopeGroup) => {
  const treeIndex = useIndex();
  const context = useGroupContext("GroupItem", __scopeGroup);
  React.useEffect(() => {
    context.onItemMount();
    return () => {
      context.onItemUnmount();
    };
  }, []);
  if (!treeIndex) {
    throw Error("<Group.Item/> should only be used within a <Group/>");
  }
  const isFirst = treeIndex.index === 0;
  const isLast = treeIndex.index === treeIndex.maxIndex;
  const disabled = childrenProps.disabled ?? context.disabled;
  let propsToPass = {
    disabled
  };
  if (context.disablePassBorderRadius !== true) {
    const borderRadius = getBorderRadius({
      radius: context.radius,
      isFirst,
      isLast,
      vertical: context.vertical,
      disable: context.disablePassBorderRadius
    });
    return { ...propsToPass, ...borderRadius };
  }
  return propsToPass;
}, "useGroupItem");
const Group = createGroup(true);
const YGroup = Group;
const XGroup = createGroup(false);
const wrapScroll = /* @__PURE__ */ __name(({ scrollable, orientation, showScrollIndicator = false }, children) => {
  if (scrollable)
    return /* @__PURE__ */ React.createElement(
      ScrollView,
      {
        ...orientation === "vertical" && {
          showsVerticalScrollIndicator: showScrollIndicator
        },
        ...orientation === "horizontal" && {
          horizontal: true,
          showsHorizontalScrollIndicator: showScrollIndicator
        }
      },
      children
    );
  return children;
}, "wrapScroll");
const getBorderRadius = /* @__PURE__ */ __name(({
  isFirst,
  isLast,
  radius,
  vertical,
  disable
}) => {
  return {
    borderTopLeftRadius: isFirst && disable !== "top" && disable !== "start" ? radius : 0,
    borderTopRightRadius: disable !== "top" && disable !== "end" && (vertical && isFirst || !vertical && isLast) ? radius : 0,
    borderBottomLeftRadius: disable !== "bottom" && disable !== "start" && (vertical && isLast || !vertical && isFirst) ? radius : 0,
    borderBottomRightRadius: isLast && disable !== "bottom" && disable !== "end" ? radius : 0
  };
}, "getBorderRadius");
const cloneElementWithPropOrder = /* @__PURE__ */ __name((child, props) => {
  const next = mergeProps(child.props, props, false, getConfig().shorthands)[0];
  return React.cloneElement({ ...child, props: null }, next);
}, "cloneElementWithPropOrder");
export {
  Group,
  GroupFrame,
  XGroup,
  YGroup,
  useGroupItem
};
