var __defProp = Object.defineProperty;
var __name = (target, value) => __defProp(target, "name", { value, configurable: true });
const registerFocusable = /* @__PURE__ */ __name((id, input) => () => {
}, "registerFocusable");
const unregisterFocusable = /* @__PURE__ */ __name((id) => {
}, "unregisterFocusable");
const focusFocusable = /* @__PURE__ */ __name((id) => {
}, "focusFocusable");
export {
  focusFocusable,
  registerFocusable,
  unregisterFocusable
};
