var __defProp = Object.defineProperty;
var __name = (target, value) => __defProp(target, "name", { value, configurable: true });
const TamaguiIconSvg = /* @__PURE__ */ __name((props) => /* @__PURE__ */ React.createElement("svg", { width: 424, height: 394, viewBox: "0 0 424 394", ...props }, /* @__PURE__ */ React.createElement("g", { fill: "none", fillRule: "evenodd" }, /* @__PURE__ */ React.createElement(
  "path",
  {
    d: "M310.786 30v28.75H372.5l-.001 28.75H426.5v86.25h-54.001l.001 28.75h-61.714v115h-28.929V375h-202.5v-57.5H21.5v-230h57.857V30h231.429Z",
    opacity: 0.267
  }
), /* @__PURE__ */ React.createElement("g", { fill: "#000" }, /* @__PURE__ */ React.createElement("path", { d: "M279.5 0h-20v20h20zM250.7 0h-20v20h20zM221.9 0h-20v20h20zM193.1 0h-20v20h20zM164.3 0h-20v20h20zM135.5 0h-20v20h20zM106.7 28.714h-20v20h20zM77.9 57.429h-20v20h20zM49.1 86.143h-20v20h20zM49.1 114.857h-20v20h20zM49.1 143.571h-20v20h20zM49.1 172.286h-20v20h20zM20.3 201H.3v20h20zM20.3 229.714H.3v20h20zM20.3 258.429H.3v20h20zM49.1 287.143h-20v20h20zM77.9 315.857h-20v20h20zM106.7 344.571h-20v20h20zM135.5 373.286h-20v20h20zM164.3 373.286h-20v20h20zM193.1 344.571h-20v20h20zM221.9 344.571h-20v20h20zM221.9 373.286h-20v20h20zM250.7 373.286h-20v20h20zM279.5 344.571h-20v20h20zM279.5 315.857h-20v20h20zM308.3 287.143h-20v20h20zM337.1 229.714h-20v20h20zM337.1 258.429h-20v20h20zM337.1 201h-20v20h20zM365.9 172.286h-20v20h20zM394.7 172.286h-20v20h20zM423.5 143.571h-20v20h20zM394.7 114.857h-20v20h20zM365.9 114.857h-20v20h20zM337.1 114.857h-20v20h20zM423.5 86.143h-20v20h20zM423.5 114.857h-20v20h20zM394.7 57.429h-20v20h20z" }), /* @__PURE__ */ React.createElement("path", { d: "M394.7 57.429h-20v20h20zM365.9 57.429h-20v20h20zM308.3 28.714h-20v20h20zM279.5 57.429h-20v20h20zM193.1 86.143h-20v20h20zM193.1 201h-20v20h20zM193.1 229.714h-20v20h20zM164.3 258.429h-20v20h20zM135.5 229.714h-20v20h20zM135.5 201h-20v20h20zM337.1 57.429h-20v20h20z" })))), "TamaguiIconSvg");
export {
  TamaguiIconSvg
};
