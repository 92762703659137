const _cn = "  _w-550 _pr-7 _pl-7 _fd-column _miw-0 _mih-0 _pos-relative _fb-auto _dsp-flex _fs-0 _ai-stretch  _pr-_gtSm_32 _pl-_gtSm_32";
import { Stack } from '@tamagui/core';
import { Heading } from '@tamagui/sandbox-ui';

// for copy/paste easily to site examples

export default (props => <div className={_cn}>
    {/* <Heading size={props.big ? 'large' : 'small'}>Lorem ipsum dolor.</Heading> */}
  </div>);

require("/Users/mohammadjkhazali/Downloads/tamagui-1.30.0/apps/sandbox/usecases/Example.tsx.18.tamagui.css!=!/Users/mohammadjkhazali/Downloads/tamagui-1.30.0/packages/loader/dist/cjs/css.js?cssPath=/Users/mohammadjkhazali/Downloads/tamagui-1.30.0/apps/sandbox/usecases/Example.tsx.18.tamagui.css!/Users/mohammadjkhazali/Downloads/tamagui-1.30.0/apps/sandbox/usecases/Example.tsx")