var __defProp = Object.defineProperty;
var __name = (target, value) => __defProp(target, "name", { value, configurable: true });
import { createFont, getVariableValue, isWeb } from "@tamagui/core";
const createInterFont = /* @__PURE__ */ __name((font = {}, {
  sizeLineHeight = /* @__PURE__ */ __name((size) => size + 10, "sizeLineHeight"),
  sizeSize = /* @__PURE__ */ __name((size) => size * 1, "sizeSize")
} = {}) => {
  const size = Object.fromEntries(
    Object.entries({
      ...defaultSizes,
      ...font.size
    }).map(([k, v]) => [k, sizeSize(+v)])
  );
  return createFont({
    family: isWeb ? 'Inter, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif' : "Inter",
    lineHeight: Object.fromEntries(
      Object.entries(size).map(([k, v]) => [k, sizeLineHeight(getVariableValue(v))])
    ),
    weight: {
      4: "300"
    },
    letterSpacing: {
      4: 0
    },
    ...font,
    size
  });
}, "createInterFont");
const defaultSizes = {
  1: 11,
  2: 12,
  3: 13,
  4: 14,
  true: 14,
  5: 16,
  6: 18,
  7: 20,
  8: 23,
  9: 30,
  10: 46,
  11: 55,
  12: 62,
  13: 72,
  14: 92,
  15: 114,
  16: 134
};
export {
  createInterFont
};
