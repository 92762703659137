const _cn = "  _mt-auto _mr-auto _mb-auto _ml-auto _maw-700 _fd-column _miw-0 _mih-0 _pos-relative _fb-auto _dsp-flex _fs-0 _ai-stretch ";
import { Toast, ToastProvider, ToastViewport } from '@tamagui/toast';
import React from 'react';
import { Button, YStack } from 'tamagui';
export default function ToastCase() {
  const [count, setCount] = React.useState(0);
  return <ToastProvider>
      <Button data-testid="button-add-toast" onPress={() => setCount(count => count + 1)}>
        Add toast
      </Button>
      <div className={_cn}>
        <Button data-testid="button-before">Focusable before viewport</Button>

        {[...Array(count)].map((_, index) => {
        const identifier = index + 1;
        return <Toast key={index} open data-testid={`toast-${identifier}`}>
              <Toast.Title>Toast {identifier} title</Toast.Title>
              <Toast.Description>Toast {identifier} description</Toast.Description>

              <Toast.Close aria-label="Close" asChild>
                <Button data-testid={`toast-button-${identifier}.1`}>{`Toast button ${identifier}.1`}</Button>
              </Toast.Close>
              <Toast.Action altText="Go and perform an action" mt="$2" asChild>
                <Button data-testid={`toast-button-${identifier}.2`}>{`Toast button ${identifier}.2`}</Button>
              </Toast.Action>
            </Toast>;
      })}
        <ToastViewport />

        <Button data-testid="button-after">Focusable after viewport</Button>
      </div>
    </ToastProvider>;
}

require("/Users/mohammadjkhazali/Downloads/tamagui-1.30.0/apps/sandbox/usecases/ToastCase.tsx.30.tamagui.css!=!/Users/mohammadjkhazali/Downloads/tamagui-1.30.0/packages/loader/dist/cjs/css.js?cssPath=/Users/mohammadjkhazali/Downloads/tamagui-1.30.0/apps/sandbox/usecases/ToastCase.tsx.30.tamagui.css!/Users/mohammadjkhazali/Downloads/tamagui-1.30.0/apps/sandbox/usecases/ToastCase.tsx")