var __defProp = Object.defineProperty;
var __name = (target, value) => __defProp(target, "name", { value, configurable: true });
import { _mutateTheme } from "./_mutateTheme";
function updateTheme({
  name,
  theme
}) {
  return _mutateTheme({ name, theme, insertCSS: true, mutationType: "update" });
}
__name(updateTheme, "updateTheme");
globalThis["updateTheme"] = updateTheme;
export {
  updateTheme
};
