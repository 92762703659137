const _cn = "  is_H1 font_heading _mt-0 _mr-0 _mb-0 _ml-0 _col-d0t1114586361 _ff-Inter _dsp-flex _tt-none _fow-700 _ls--3 _fos-46 _lh-56 ";
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useWindowDimensions } from 'react-native';
import { Button, H1, Dialog as TDialog, YStack } from 'tamagui';

/* Minimal test case for reproducing crashes */
export function TestModal() {
  const {
    width: windowWidth,
    height: windowHeight
  } = useWindowDimensions();
  return <TDialog>
      <TDialog.Trigger asChild>
        <Button>hi2</Button>
      </TDialog.Trigger>

      <TDialog.Adapt when="md">
        <TDialog.Sheet modal snapPoints={[20, 40, 80]}>
          <TDialog.Sheet.Handle h={10} bc="$core8" o={1} // Uncommenting this causes a rare crash on native iOS Safari
        width="25%" m="$0" mb="$1" mt="$5" alignSelf="center" pos="absolute" t={-40} />

          <TDialog.Sheet.Overlay h={windowHeight} w={windowWidth} bc="$background" />

          {/* Uncommenting this causes a rare crash on native iOS Safari */}
          <TDialog.Sheet.Frame theme="red">
            <TDialog.Sheet.ScrollView>
              <TDialog.Adapt.Contents />
            </TDialog.Sheet.ScrollView>
          </TDialog.Sheet.Frame>
        </TDialog.Sheet>
      </TDialog.Adapt>

      <TDialog.Portal>
        <TDialog.Content>
          <YStack space p="$4">
            <h1 className={_cn}>
              Esse nulla magna reprehenderit sunt ea elit. Voluptate amet elit
              reprehenderit tempor duis duis. Nostrud adipisicing duis in sunt adipisicing
              nulla culpa. Est cillum esse reprehenderit officia incididunt ea aliquip
              aliqua quis ex cillum.
            </h1>

            <h1 className={_cn}>
              Esse nulla magna reprehenderit sunt ea elit. Voluptate amet elit
              reprehenderit tempor duis duis. Nostrud adipisicing duis in sunt adipisicing
              nulla culpa. Est cillum esse reprehenderit officia incididunt ea aliquip
              aliqua quis ex cillum.
            </h1>
          </YStack>
        </TDialog.Content>
      </TDialog.Portal>
    </TDialog>;
}

/* Minimal test case for reproducing crashes */
function TestModal10() {
  return <>
      <TestModal />
      <TestModal />
      <TestModal />
      <TestModal />
      <TestModal />
      <TestModal />
      <TestModal />
      <TestModal />
      <TestModal />
      <TestModal />
    </>;
}

/* Minimal test case for reproducing crashes */
export default function TestModal100() {
  return <>
      <TestModal10 />
      <TestModal10 />
      <TestModal10 />
      <TestModal10 />
      <TestModal10 />

      <TestModal10 />
      <TestModal10 />
      <TestModal10 />
      <TestModal10 />
      <TestModal10 />
    </>;
}

require("/Users/mohammadjkhazali/Downloads/tamagui-1.30.0/apps/sandbox/usecases/CrashAdaptSheet.tsx.12.tamagui.css!=!/Users/mohammadjkhazali/Downloads/tamagui-1.30.0/packages/loader/dist/cjs/css.js?cssPath=/Users/mohammadjkhazali/Downloads/tamagui-1.30.0/apps/sandbox/usecases/CrashAdaptSheet.tsx.12.tamagui.css!/Users/mohammadjkhazali/Downloads/tamagui-1.30.0/apps/sandbox/usecases/CrashAdaptSheet.tsx")